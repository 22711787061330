import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

function SearchResults() {
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [results, setResults] = useState([]);
  const [noResults, setNoResults] = useState(false);
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const handleOnline = () => setIsOnline(true);
    const handleOffline = () => setIsOnline(false);

    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, []);

  useEffect(() => {
    if (location.state && location.state.search) {
      setSearch(location.state.search);
      handleSearch(location.state.search);
    }
  }, [location.state]);

  const handleSearch = async (searchTerm) => {
    if (!searchTerm.trim()) {
      return;
    }

    setLoading(true);
    setNoResults(false);

    try {
      const response = await fetch(
        `${
          process.env.REACT_APP_BACKEND_URL
        }/api/v1/masjid/info?searchString=${encodeURIComponent(searchTerm)}`
      );
      const data = await response.json();

      if (data.length === 0) {
        setNoResults(true);
        setResults([]);
      } else {
        setResults(data);
      }
    } catch (error) {
      console.error("Failed to fetch data:", error);
      setNoResults(true);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = () => {
    handleSearch(search);
  };

  const handleViewDetails = (masjid) => {
    navigate(`/masjid/${masjid.username}`);
  };

  return (
    <>
      <div className="container-fluid full-width-container d-flex justify-content-center align-items-center">
        <h1 style={{ textAlign: "center", color: "#ffffff" }}>
          <strong>MIS</strong> Masajid Information
        </h1>
      </div>

      {!isOnline && (
        <div style={{ marginTop: 64, textAlign: "center" }}>
          <h1 style={{ color: "#c40e0e", marginTop: 64 }}>
            Please connect to the internet
          </h1>
        </div>
      )}

      {isOnline && (
        <div
          className="d-flex d-md-flex justify-content-center justify-content-md-center"
          style={{ marginTop: 24 }}
        >
          <div
            className="input-group"
            style={{
              width: "95%",
              background: "#ffffff",
              borderRadius: 24,
              border: "1px solid #333333",
            }}
          >
            <input
              className="form-control py-2 rounded-pill mr-1 pr-5 search-input"
              type="search"
              style={{ background: "transparent", borderStyle: "none" }}
              placeholder="Masjid Name in English/Urdu"
              name="search"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              onKeyUp={(e) => {
                if (e.key === "Enter") {
                  handleSubmit();
                }
              }}
            />
            <span
              className="input-group-text input-group-append"
              style={{
                background: "#333333",
                borderStyle: "none",
                borderRadius: 24,
                borderTopLeftRadius: 0,
                borderTopRightRadius: 24,
                borderBottomRightRadius: 24,
                borderBottomLeftRadius: 0,
              }}
            >
              <button
                className="btn rounded-pill border-0 ml-n5"
                type="button"
                onClick={handleSubmit}
              >
                <i className="fas fa-search" style={{ color: "#ffffff" }} />
              </button>
            </span>
          </div>
        </div>
      )}

      {isOnline && loading && (
        <div
          className="d-flex justify-content-center"
          style={{ marginTop: 24 }}
        >
          <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      )}

      {noResults && !loading && (
        <div className="d-flex justify-content-center">
          <div
            className="card"
            style={{
              width: "95%",
              background: "#333333",
              borderRadius: 24,
              color: "#ffffff",
              marginTop: 24,
            }}
          >
            <div className="card-body">
              <h4
                className="card-title"
                style={{ textAlign: "center", marginBottom: 0 }}
              >
                No results found
              </h4>
            </div>
          </div>
        </div>
      )}

      {!loading && results.length > 0 && (
        <>
          {results.map((masjid) => (
            <div
              key={masjid.username}
              className="d-flex justify-content-center"
              style={{ marginTop: 24 }}
            >
              <div
                className="card"
                style={{
                  width: "95%",
                  background: "#333333",
                  borderRadius: 24,
                  color: "#ffffff",
                }}
                onClick={() => handleViewDetails(masjid)}
              >
                <div className="card-body">
                  <h4 className="card-title" style={{ textAlign: "center" }}>
                    {masjid.engName}
                  </h4>
                  <h4
                    className="card-subtitle mb-2"
                    style={{ textAlign: "center" }}
                  >
                    {masjid.urdName}
                  </h4>
                  <p className="card-text" style={{ textAlign: "center" }}>
                    {masjid.address}
                  </p>
                  <p
                    style={{
                      textAlign: "center",
                      marginBottom: 0,
                      color: "rgb(7,240,255)",
                      cursor: "pointer",
                    }}
                  >
                    Click to view details
                  </p>
                </div>
              </div>
            </div>
          ))}
        </>
      )}
    </>
  );
}

export default SearchResults;