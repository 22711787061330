import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

function Main() {
  const [search, setSearch] = useState("");
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const navigate = useNavigate();

  useEffect(() => {
    const handleOnline = () => setIsOnline(true);
    const handleOffline = () => setIsOnline(false);

    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, []);

  const handleSearch = () => {
    if (search.trim()) {
      navigate("/search-results", { state: { search } });
    }
  };

  return (
    <>
      <div className="container-fluid full-width-container d-flex justify-content-center align-items-center">
        <h1 style={{ textAlign: "center", color: "#ffffff" }}>
          <strong>MIS</strong> Masajid Information
        </h1>
      </div>

      {!isOnline && (
        <div style={{ marginTop: 64, textAlign: "center" }}>
          <h1 style={{ color: "#c40e0e", marginTop: 64 }}>
            Please connect to the internet
          </h1>
        </div>
      )}

      {isOnline && (
        <div
          className="d-flex d-md-flex justify-content-center justify-content-md-center"
          style={{ marginTop: 24 }}
        >
          <div
            className="input-group"
            style={{
              width: "95%",
              background: "#ffffff",
              borderRadius: 24,
              border: "1px solid #333333",
            }}
          >
            <input
              className="form-control py-2 rounded-pill mr-1 pr-5 search-input"
              type="search"
              style={{ background: "transparent", borderStyle: "none" }}
              placeholder="Masjid Name in English/Urdu"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              onKeyUp={(e) => {
                if (e.key === "Enter") {
                  handleSearch();
                }
              }}
            />
            <span
              className="input-group-text input-group-append"
              style={{
                background: "#333333",
                borderStyle: "none",
                borderRadius: 24,
                borderTopLeftRadius: 0,
                borderTopRightRadius: 24,
                borderBottomRightRadius: 24,
                borderBottomLeftRadius: 0,
              }}
            >
              <button
                className="btn rounded-pill border-0 ml-n5"
                type="button"
                onClick={handleSearch}
              >
                <i className="fas fa-search" style={{ color: "#ffffff" }} />
              </button>
            </span>
          </div>
        </div>
      )}
    </>
  );
}

export default Main;