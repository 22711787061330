import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Main from "./components/Main";
import SearchResults from "./components/SearchResults";
import MasjidDetails from "./components/MasjidDetails";
import NotFound from "./components/NotFound";

function App() {
  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const [isAppInstalled, setIsAppInstalled] = useState(false);
  const [isIos, setIsIos] = useState(false);

  useEffect(() => {
    const handleBeforeInstallPrompt = (e) => {
      e.preventDefault();
      setDeferredPrompt(e);
    };

    window.addEventListener("beforeinstallprompt", handleBeforeInstallPrompt);

    if (window.matchMedia("(display-mode: standalone)").matches) {
      setIsAppInstalled(true);
    }

    if (
      navigator.userAgent.match(/iPhone/i) ||
      navigator.userAgent.match(/iPod/i) ||
      navigator.userAgent.match(/iPad/i)
    ) {
      setIsIos(true);
    }

    return () => {
      window.removeEventListener(
        "beforeinstallprompt",
        handleBeforeInstallPrompt
      );
    };
  }, []);

  const handleInstallClick = () => {
    if (deferredPrompt) {
      deferredPrompt.prompt();
      deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === "accepted") {
          console.log("User accepted the install prompt");
        } else {
          console.log("User dismissed the install prompt");
        }
        setDeferredPrompt(null);
      });
    }
  };

  return (
    <div className="App">
      <Router>
        <div>
        <Routes>
          <Route path="/" element={<Main />} />
          <Route path="/search-results" element={<SearchResults />} />
          <Route path="/masjid/:username" element={<MasjidDetails />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
        </div>
      </Router>
      {!isAppInstalled && (
        <>
          {deferredPrompt && (
            <div className="install-button-container">
              <button onClick={handleInstallClick} className="install-button">
                Install App
              </button>
            </div>
          )}
          {isIos && !deferredPrompt && (
            <div className="ios-install-guide">
              <p>To add this app to your home screen:</p>
              <ol>
                <li>Open the Share menu in Safari.</li>
                <li>Select "Add to Home Screen".</li>
                <li>Follow the instructions to install the app.</li>
              </ol>
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default App;
