import React from 'react'
import { Link } from 'react-router-dom'

function NotFound() {
    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh',
                paddingLeft: 10,
                paddingRight: 10,
            }}
        >
            <div
                className="card"
                style={{
                    background: '#333333',
                    borderRadius: '8px',
                }}
            >
                <div className="card-body">
                    <h1
                        className="text-muted card-subtitle mb-2"
                        style={{
                            textAlign: 'center',
                            marginTop: 10,
                            marginBottom: 12,
                        }}
                    >
                        <span style={{ color: '#c40e0e' }}>404</span>{' '}
                        <span style={{ color: '#ffffff' }}>
                            - Page Not Found
                        </span>
                    </h1>
                    <p
                        className="card-text"
                        style={{
                            textAlign: 'center',
                            marginBottom: 36,
                            marginTop: 27,
                        }}
                    >
                        <span style={{ color: '#ffffff' }}>
                            The page you are looking for may have been moved,
                            deleted, or possibly never existed. Click{' '}
                        </span>
                        <Link to="/">
                            <span
                                style={{
                                    textDecoration: 'underline',
                                    color: '#ffffff',
                                }}
                            >
                                here
                            </span>
                        </Link>
                        <span style={{ color: '#ffffff' }}>
                            {' '}
                            to go back.
                        </span>
                    </p>
                </div>
            </div>
        </div>
    )
}

export default NotFound
